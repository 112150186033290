import React from "react"
//GitHubのReleasesで配布されているプラグインはReleaseページへリンクする
const Plugins = 
[
  {
    date: "2024-08-17",
    name: "YMM4扇クリッピングプラグイン",
    url: "https://benikazura.booth.pm/items/6015501",
    author: "べにかずら",
    authorUrl: "https://x.com/benikazura_X",
    description: "AviUtlスクリプト\"扇クリッピング(R)\"(作 : rikky)の機能の一部を再現した、映像を扇状にクリッピングする映像エフェクトです",
  },
  {
    date: "2024-08-12",
    name: "YMM_BrokenJpeg",
    url: "https://github.com/mes51/YMM_BrokenJpeg/releases/",
    author: "肉(mes)",
    authorUrl: "https://x.com/mes_deleted_n",
    description: "Jpeg圧縮処理を用いて画像を乱す映像エフェクトです",
  },
  {
    date: "2024-08-08",
    name: "カスタム複製エフェクト",
    url: "https://github.com/sinBetaKun/CustomCloneEffectPlugin/releases",
    author: "sinβ",
    authorUrl: "https://x.com/sinBetaKun",
    description: "映像を任意の複数の位置に複製できる映像エフェクトです",
  },
  {
    date: "2024-06-30",
    name: "キャラクターモーションプラグイン",
    url: "https://benikazura.booth.pm/items/5870342",
    author: "べにかずら",
    authorUrl: "https://x.com/benikazura_X",
    description: "立ち絵の感情表現に便利なアニメーションを多数収録した映像エフェクトです",
  },
  {
    date: "2024-06-23",
    name: "YMM4-CapCut",
    url: "https://github.com/taisan11/YMM4-CapCut",
    author: "taisan",
    authorUrl: "https://x.com/taisan_minna",
    description: "YMM4からCapCutの合成音声を利用可能にする音声合成プラグインです",
  },
  {
    date: "2024-06-05",
    name: "YMM4CustomEasengK",
    url: "https://github.com/kotolin-sub/YMM4CustomEasengK/releases",
    author: "kotolin",
    authorUrl: "https://x.com/kotolind2a1",
    description: "luaスクリプトを使用して座標を操作可能にする映像エフェクトです",
  },
  {
    date: "2024-06-01",
    name: "YMM4Whisper",
    url: "https://github.com/kotolin-sub/YMM4Whisper/releases",
    author: "kotolin",
    authorUrl: "https://x.com/kotolind2a1",
    description: "音声を囁き声にする音声エフェクトです",
  },
  {
    date: "2024-05-24",
    name: "YPEI4",
    url: "https://benikazura.booth.pm/items/5734530",
    author: "RainKasa",
    authorUrl: "https://x.com/amekasa_",
    description: "【プラグイン開発者向け】YMM4のプラグインを簡単に導入できるようにするプロジェクトです",
  },
  {
    date: "2024-05-18",
    name: "YMM4ConsolePlugin",
    url: "https://github.com/kotolin-sub/YMM4_Console_Plugin/releases",
    author: "kotolin",
    authorUrl: "https://x.com/kotolind2a1",
    description: "【プラグイン開発者向け】デバッグウィンドウを追加するプラグインです",
  },
  {
    date: "2024-05-11",
    name: "ウェーブテキストプラグイン",
    url: "https://benikazura.booth.pm/items/5734530",
    author: "べにかずら",
    authorUrl: "https://twitter.com/benikazura_X",
    description: "テキストを波状に配置する映像エフェクトです",
  },
  {
    date: "2024-05-05",
    name: "時間差複製エフェクト",
    url: "https://github.com/sinBetaKun/ShiftCloneEffectPlugin/",
    author: "sinβ",
    authorUrl: "https://twitter.com/sinBetaKun",
    description: "指定した方向に、指定された時間間隔で複製して表示する映像エフェクトです",
  },
  {
    date: "2024-04-29",
    name: "PolarDistortion",
    url: "https://github.com/mes51/YMM_PolarDistortion/releases/",
    author: "肉(mes)",
    authorUrl: "https://twitter.com/mes_deleted_n",
    description: "直交座標から極座標、極座標から直交座標に変換する映像エフェクトです。巨大な画像に対しても適用することができます。",
  },
  {
    date: "2024-04-25",
    name: "反復反転",
    url: "https://github.com/Shahulog/FlipEffectPlugin/releases",
    author: "しゃふろぐ",
    authorUrl: "https://twitter.com/Shahu_log",
    description: "繰り返し反転させる映像エフェクト",
  },
  {
    date: "2024-04-22",
    name: "点滅しながら登場退場",
    url: "https://github.com/Shahulog/BlinkInYMM4Plugin",
    author: "しゃふろぐ",
    authorUrl: "https://twitter.com/Shahu_log",
    description: "点滅しながら登場退場させる映像エフェクトです",
  },
  {
    date: "2024-03-13",
    name: "ビデオチェッカープラグイン",
    url: "https://benikazura.booth.pm/items/5567040",
    author: "べにかずら",
    authorUrl: "https://twitter.com/benikazura_X",
    description: "映像アイテム(図形アイテムや動画アイテム等)の内部数値を調べることができる映像エフェクトです",
  },
  {
    date: "2024-02-16",
    name: "YMMプラグイン集vol.1",
    url: "https://github.com/SomeTea01/YMM4PluginPack_vol1/releases",
    author: "Sometea_1024",
    authorUrl: "https://twitter.com/bluesky_1024",
    description: "正多角形、モールス信号生成エフェクト、音声波形生成エフェクト、円運動エフェクト、コマ落ちエフェクト、ポスタリゼーションエフェクトを含むプラグインパックです。",
  },
  {
    date: "2024-02-15",
    name: "数値テキストプラグイン",
    url: "https://github.com/Piyo-creator/YMM4PpS/releases/",
    author: "ぴよ",
    authorUrl: "https://twitter.com/Piyo_creator",
    description: "カンマ区切りや小数点の任意の桁数表示に対応した数値表示プラグインです",
  },
  {
    date: "2024-01-09",
    name: "Volumetric FractalNoise",
    url: "https://github.com/mes51/YMM_VolumetricFractalNoise/releases/",
    author: "肉(mes)",
    authorUrl: "https://twitter.com/mes_deleted_n",
    description: "3Dのフラクタルノイズを生成する映像エフェクトです",
  },
  {
    date: "2023-12-25",
    name: "スクロールプラグイン",
    url: "https://benikazura.booth.pm/items/5370333",
    author: "べにかずら",
    authorUrl: "https://twitter.com/benikazura_X",
    description: "一定の方向に繰り返しスクロールを行う映像エフェクトを追加する映像エフェクトです",
  },
  {
    date: "2023-11-26",
    name: "点滅プラグイン",
    url: "https://github.com/68B09/YMM4Plugins",
    author: "ZZO",
    authorUrl: "https://twitter.com/MB68B09",
    description: "指定秒数で表示と非表示を繰り返す映像エフェクトです",
  },
  {
    date: "2023-11-24",
    name: "YukkuriMovieMaker4SoVitsSvcFork",
    url: "https://github.com/34j/YukkuriMovieMaker4SoVitsSvcFork",
    author: "34j",
    authorUrl: "https://github.com/34j",
    description: "so-vits-svc-forkを使用してボイスチェンジャーを適用する音声エフェクトです",
  },
  {
    date: "2023-11-20",
    name: "レターボックスプラグイン",
    url: "https://benikazura.booth.pm/items/5269715",
    author: "べにかずら",
    authorUrl: "https://twitter.com/benikazura_X",
    description: "図形オブジェクトにレターボックス（画面上下や左右の黒帯）を追加する映像エフェクトです",
  },
  {
    date: "2023-10-24",
    name: "描画変換プラグイン",
    url: "https://benikazura.booth.pm/items/5199154",
    author: "べにかずら",
    authorUrl: "https://twitter.com/benikazura_X",
    description: "拡大率、回転角、不透明度、左右反転をエフェクトを適用したタイミングで処理する映像エフェクトです",
  },
  {
    date: "2023-10-11",
    name: "Unmultプラグイン",
    url: "https://github.com/mes51/YMM_Unmult/releases/",
    author: "肉(mes)",
    authorUrl: "https://twitter.com/mes_deleted_n",
    description: "黒背景素材を透過素材に変換する映像エフェクトです",
  },
  {
    date: "2023-10-09",
    name: "疑似不透明度プラグイン",
    url: "https://benikazura.booth.pm/items/5156386",
    author: "べにかずら",
    authorUrl: "https://twitter.com/benikazura_X",
    description: "背景に単色化画像を重ねながら不透明度を調整できる映像エフェクトです",
  },
  {
    date: "2023-10-06",
    name: "グリッチノイズプラグイン",
    url: "https://benikazura.booth.pm/items/5126133",
    author: "べにかずら",
    authorUrl: "https://twitter.com/benikazura_X",
    description: "グリッチ効果（画面が乱れたような効果）を付与する映像エフェクトです",
  },
  {
    date: "2023-09-12",
    name: "クソでか画像縮小プラグイン",
    url: "https://www.youtube.com/watch?v=ET6aQy0AClA",
    author: "ゆっくりコンピュータサイエンス",
    authorUrl: "https://twitter.com/yukkuri_cs_",
    description: "1920x1080を超えるサイズの画像を予め縮小した状態で読み込むプラグインです。",
  },
  {
    isEnabled: false,
    date: "2023-09-03",
    name: "音割れプラグイン",
    url: "https://ux.getuploader.com/benikazura_ymm4plugin/download/1",
    author: "べにかずら",
    authorUrl: "https://twitter.com/benikazura_X",
    description: "音声の音割れさせる音声エフェクトです",
  },
];

const PluginList = ({max})=>
{  
  return Plugins
    .slice(0, max)
    .filter((plugin,i)=>plugin.isEnabled != false)
    .sort((a,b)=>new Date(b.date) - new Date(a.date))
    .map((plugin,i)=>
    {
      return (
        <tr key={i}>
          <td>
            <a href={plugin.url} target="_blank" rel="noopener noreferrer">{plugin.name}</a>
          </td>
          <td>
            <a href={plugin.authorUrl} target="_blank" rel="noopener noreferrer">{plugin.author}</a>
          </td>
          <td>
            {plugin.description}
          </td>
        </tr>)
    });
};

const YMM4Plugins = ({max})=>{
  return (
    <table>
      <thead>
        <tr>
          <th>名前・配布元</th>
          <th>開発者</th>
          <th>説明</th>
        </tr>
      </thead>
      <tbody>
        <PluginList max={max}/>
      </tbody>
    </table>
  );
}

export default YMM4Plugins;
